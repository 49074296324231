import { inject, Injectable } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { filter, type Observable, Subject, switchMap } from 'rxjs';
import {
  Fields,
  FieldUpdatedEvent,
  ICompany,
  ProductCategories,
  Section,
  SortedKeyValue,
  SubIndustries,
} from '@network/company/state/company.entities';
import { PagingWrapper } from '@core/helpers/paging';
import { ConnectionStatus } from '@core/models/company';
import { CompanyFieldsSchema } from '@network/company/state/company-fields-schema';

export interface PublicDataPayload {
  name: string;
  description: string;
  businessType: SortedKeyValue;
  companyType: SortedKeyValue;
  websiteUrl: string;
  yearStarted: number;
  tickerSymbol: string;
  numberOfEmployees: SortedKeyValue;
  annualRevenue: SortedKeyValue;
  subIndustries: SubIndustries;
  productCategories: ProductCategories;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private fieldUpdatedEventNotifier$ = new Subject<FieldUpdatedEvent>();
  private readonly _http = inject(HttpService);

  subscribeToFieldUpdatedEvent(fieldId: number): Observable<FieldUpdatedEvent> {
    return this.fieldUpdatedEventNotifier$.asObservable().pipe(filter((event) => event.fieldId === fieldId));
  }

  sendFieldUpdatedEvent(event: FieldUpdatedEvent): void {
    this.fieldUpdatedEventNotifier$.next(event);
  }

  getCompanyById(id: number): Observable<ICompany> {
    return this._http.getV2<ICompany>(`/api/companies/${id}`);
  }

  getFieldsSchema(): Observable<CompanyFieldsSchema> {
    return this._http.getV2<CompanyFieldsSchema>('/api/companies/fields/schema');
  }

  getMyCompanyConnections(limit: number = 10, offset: number = 0): Observable<PagingWrapper<ICompany>> {
    return this._http.postV2(`/api/globalSearch/companies?offset=${offset}&limit=${limit}`, {
      connectionStatusIds: [ConnectionStatus.Connected],
    });
  }

  updateCompanyLogo(
    companyId: number,
    fullSizeLogoId: string,
    editedLogoId: string,
    logoSettings: string,
  ): Observable<ICompany> {
    return this._http.putV2(`api/companies/${companyId}/logo`, {
      logo: {
        logoImageId: editedLogoId,
        fullSizeLogoImageId: fullSizeLogoId,
        logoSettings,
      },
    });
  }

  updatePublicData(companyId: number, fields: Fields[]): Observable<ICompany> {
    const companyFieldValues = fields.reduce((acc, field) => {
      acc[field.fieldId] = field.value;
      return acc;
    }, {});

    return this._http.putV2(`/api/companies/${companyId}/fields`, {
      companyFieldValues,
    });
  }

  updatePrivateField(companyId: number, field: Fields): Observable<ICompany> {
    return this._http.putV2(`/api/companies/${companyId}/privateFields`, {
      companyFieldValues: {
        [field.fieldId]: field.value,
      },
    });
  }

  updateConfidentialField(companyId: number, field: Fields): Observable<ICompany> {
    return this._http.putV2(`/api/companies/${companyId}/confidentialFields`, {
      companyFieldValues: {
        [field.fieldId]: field.value,
      },
    });
  }

  updateCustomField(masterCompanyId: number, sharedWithCompanyId: number, field: Fields): Observable<ICompany> {
    return this._http
      .putV2(`/api/companies/${masterCompanyId}/connectedCompanies/${sharedWithCompanyId}/proprietaryFields`, {
        companyFieldValues: {
          [field.fieldId]: field.value,
        },
      })
      .pipe(switchMap(() => this.getCompanyById(masterCompanyId)));
  }

  getProprietaryFields(masterCompanyId: number, companyId: number): Observable<Section[]> {
    return this._http.getV2<Section[]>(
      `/api/companies/${masterCompanyId}/connectedCompanies/${companyId}/proprietaryFields`,
    );
  }
}
